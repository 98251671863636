import React from "react";
import { Col, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import config from "../../data/SiteConfig";
import Layout from "../layout";
import * as S from "../styles/styles.css";

function SoftwarePage() {
  return (
    <Layout location="software" title={false}>
   
        <Helmet title={`About | ${config.siteTitle}`} />
         <S.StandardContainer>  
          <Row style={{ minHeight: "calc(100vh - 104px)" }}>
            <Col>
              <h3>Stone</h3>
              <p>Lead Designer at write In Stone</p>
              {/* <p>Lorem ispum dolor sit amet, consectetur adipiscing el</p>
              <p>
                Lorem ispum dolor sit amet, consectetur adipiscing el Lorem
                ispum dolor sit amet, consectetur adipiscing el Lorem ispum
                dolor sit amet, consectetur adipiscing el
              </p>
              <h3>Website</h3>
              <div
                style={{
                  width: "100%",
                  height: "600px",
                  display: "block",
                  background: "green",
                  borderRadius: "5px"
                }}
              /> */}
            </Col>
          </Row>
      </S.StandardContainer>
    </Layout>
  );
}

export default SoftwarePage;
